<template>
  <div class="px-9 mt-5">
    <v-list-item-group class="mb-5">
      <v-list-item-content class="py-4 pl-0">
        <s-text weight="bold" size="md" color="grayTextAlt">
          Are you sure you want to delete this training data?
        </s-text>
      </v-list-item-content>
    </v-list-item-group>
    <div class="d-flex align-center">
      <s-btn
        @click="deleteUser"
        :loading="isDeleting"
        type="button"
        color="primary"
        elevation="0"
      >
        <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
        Confirm
      </s-btn>
      <s-btn
        weight="medium"
        size="md-m"
        class="delete-btn ml-4"
        elevation="0"
        @click.stop="closeModal()"
        :disabled="isDeleting"
      >
        <v-icon class="mr-2" color="primary"> mdi-close </v-icon>
        Cancel
      </s-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteTrainingData',
  props: {
    isDeleting: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    deleteUser () {
      this.$emit('delete')
    },
  },
}
</script>
<style lang="scss" scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  border-radius: 6px;
  color: var(--v-gray-base);
  font-size: 14px;
}
</style>
