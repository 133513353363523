<template>
  <training-data-form
    :value="value"
    :is-submitting="isSubmitting"
    @submit="createTrainingData"
  />
</template>

<script>
import TrainingDataForm from '@/components/training/TrainingDataForm.vue'
export default {
  name: 'CreateApp',
  components: {
    'training-data-form': TrainingDataForm,
  },
  data () {
    return {
      isSubmitting: false,
      value: {
        question: '',
        answer: '',
      },
    }
  },
  methods: {
    createTrainingData () {
      this.isSubmitting = true
      this.$store
        .dispatch('training/createTrainingData', this.value)
        .then(() => {
          this.$emit('refresh')
          this.$router.push({ name: 'training' })
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
  },
}
</script>
<style>
.img {
  height: 300px;
}
.bg-white {
  background: white;
}
</style>
