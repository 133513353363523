<template>
  <v-form
    ref="form"
    @submit.prevent="createApp"
  >
    <v-row class="ma-5">
      <v-col>
        <s-text-field
          name="question"
          label="Question"
          type="text"
          filled
          required
          v-model="value.question"
          :error-messages="getErrors('text', $v.value.question, 'Training Data Question')"
          @blur="$v.value.question.$touch()"
        />
        <s-text-area
          name="answer"
          label="Answer"
          type="text"
          filled
          required
          v-model="value.answer"
          :error-messages="getErrors('text', $v.value.answer, 'Training Data Answer')"
          @blur="$v.value.answer.$touch()"
        />
        <div class="d-flex mt-4">
          <s-btn
            class="mr-1"
            :loading="isSubmitting"
            type="submit"
            color="primary"
            elevation="0"
          >
            Submit
          </s-btn>
          <s-btn
            class="delete-btn ml-2"
            elevation="0"
            :disabled="isSubmitting"
            @click="clearState"
            text
          >
            <v-icon>mdi-close</v-icon>
            Cancel
          </s-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { vuelidateErrors } from '@/mixin'
import { required, minLength } from 'vuelidate/lib/validators'
export default {
  name: 'TrainingDataForm',
  mixins: [vuelidateErrors],
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    value: {
      question: {
        required,
        minLength: minLength(2),
      },
      answer: { required },
    },
  },
  methods: {
    clearState () {
      // TODO
      // Add confirmation to discard data provided
      this.closeCreateApp()
    },
    closeCreateApp () {
      this.$router.go(-1)
    },
    createApp () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$emit('submit')
    },
  },
}
</script>
<style scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 26px;
  height: 23px;
  color: #5599ff;
  font-size: 14px;
}
.bg-white {
  background: white;
}

.delete-btn {
  color: #EC3944;
  background-color: #fff !important;
}
</style>
