<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container>
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="5">
          <s-text weight="medium" color="gray" size="sm">
            Question
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="6">
          <s-text weight="medium" color="gray" size="sm">
            Answer
          </s-text>
        </v-col>
      </v-row>
      <v-row
        v-for="d in data"
        :key="d.id"
        class="pa-0 ma-0 mb-3 app-card app-card-content"
      >
        <v-col class="d-flex flex-column" cols="5">
          <truncate-text
            :text="d.question"
          />
        </v-col>
        <v-col class="d-flex flex-column" cols="6">
          <truncate-text
            :text="d.answer"
          />
        </v-col>
        <v-col class="action_group d-flex align-center justify-end py-0">
          <v-btn
            depressed
            icon
            class="back-btn mr-0"
            @click.stop="initDeleteDialog(d.id)"
          >
            <s-icon name="trash" style="color: red;" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <s-dialog
      v-model="showDialog"
      persistent
      isModal
      :title="dialogTitle"
      :close-default="setDefaultDialogCloseAction"
      @close="showDeleteDialog = false; createTrainingData = false;"
    >
      <create-training-data
        v-if="showCreateModal"
        @refresh="getTrainingData"
      />
      <delete-training-data
        v-if="showDeleteDialog"
        :is-deleting="isDeleting"
        @delete="deleteTrainingData"
        @close="exitDeleteDialog"
      />
    </s-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CreateTrainingData from '@/views/training/CreateTrainingData'
import DeleteTrainingData from '@/components/training/DeleteTrainingData'
import Loader from '@/components/cards/Loader'
import TruncateText from '@/components/cards/TruncateText'
import { getHumanReadableDate } from '@/utils/date'
export default {
  name: 'Referrals',
  components: {
    loader: Loader,
    'truncate-text': TruncateText,
    'delete-training-data': DeleteTrainingData,
    'create-training-data': CreateTrainingData,
  },
  computed: {
    ...mapGetters({
      data: 'training/data',
    }),
    showDialog () {
      return this.showDeleteDialog || this.showCreateModal
    },
    dialogTitle () {
      if (this.showDeleteDialog) return 'Delete Training Data'
      if (this.showCreateModal) return 'Add Training Data'
      return 'Dialog'
    },
    setDefaultDialogCloseAction () {
      return !this.showDeleteDialog
    },
  },
  data () {
    return {
      isLoading: false,
      isDeleting: false,
      showDeleteDialog: false,
      showCreateModal: this.$route.meta.showCreateModal,
      currentTrainingDataId: null,
    }
  },
  methods: {
    getHumanReadableDate,
    initDeleteDialog (id) {
      this.currentTrainingDataId = id
      this.showDeleteDialog = true
    },
    exitDeleteDialog () {
      this.currentTrainingDataId = null
      this.showDeleteDialog = false
      this.isDeleting = false
    },
    getTrainingData () {
      this.isLoading = true
      this.$store
        .dispatch('training/getTrainingData')
        .finally(() => {
          this.isLoading = false
        })
    },
    async deleteTrainingData () {
      this.isDeleting = true
      await this.$store
        .dispatch('training/deleteTrainingData', this.currentTrainingDataId)
        .finally(() => {
          this.exitDeleteDialog()
        })
    },
  },
  watch: {
    data: {
      handler () {
        if (this.data) return
        this.getTrainingData()
      },
      immediate: true,
    },
    '$route.meta' ({ showCreateModal }) {
      this.showCreateModal = showCreateModal
    },
  },
}
</script>
