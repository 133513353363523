import { render, staticRenderFns } from "./TrainingDataForm.vue?vue&type=template&id=0d61d173&scoped=true"
import script from "./TrainingDataForm.vue?vue&type=script&lang=js"
export * from "./TrainingDataForm.vue?vue&type=script&lang=js"
import style0 from "./TrainingDataForm.vue?vue&type=style&index=0&id=0d61d173&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d61d173",
  null
  
)

export default component.exports